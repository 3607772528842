
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

iframe {
    border: none;
    margin: 0;
    padding: 0;
}
