
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '~vuetify/src/styles/settings/_variables';

.clientcabin-welcome::v-deep {
    background-color: $white;
    color: $black;

    .subtitle {
        font-size: 20px;
        line-height: 36px;
    }

    .faq {
        .v-expansion-panels {
            border: 1px solid $tertiary-color;
            border-radius: 9px;

            .v-expansion-panel {
                background-color: transparent;
            }
        }

        .v-expansion-panel-header {
            color: $black !important;

            .v-expansion-panel-header__icon svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}
