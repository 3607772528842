
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-custom-video {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        max-width: 100%;
        height: auto;
    }

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
